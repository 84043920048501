import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { preachers } from "./data";

function HomePage() {
  // const [keys, setKeys] = useState(Object.keys(preachers));
  // const [mapperData, setMapperdata] = useState(preachers)

  const keys = Object.keys(preachers);
  const mapperData = preachers


  return (
    <div>
      <h1 style={{ fontFamily: 'UN-Gurulugomi', color: "#ede609", textAlign: "center", fontSize: "min(5vw, 50px)" }}>
        "දසබලසේල පභවා නිබ්බානමහා සමුද්ද පරියන්තා අට්ඨංග මග්ගස්සලිලා ජිනවචන තදි චිරං වහභුති"
      </h1>
      <h2 style={{ fontFamily: 'UN-Derana', color: "#ede609", textAlign: "center", fontSize: "min(4vw, 40px)", padding: ('0vw', 'min(5vw, 50px)', '0vw', 'min(5vw, 50px)') }}>
        දසබලයන් වහන්සේ නැමැති ශෛලමය පර්වතයෙන් පැන නැගි අමාමහ නිවන නම්වු මහා සාගරයෙන් අවසන් කොට ඇති අර්ය අශ්ටාංගික මාර්ගය නම්වු සිසිල් දිය දහරින් හෙබි උතුම් ශ්‍රි බුද්ධ වචන ගංගාව ලො සතුන්ගේ සසර දුක නිවාලමින් බොහො කල් ගලා බස්නා සේක්වා !!
      </h2>
      <h1 style={{ fontFamily: 'UN-Arundathee', color: "#ede609", textAlign: "center", fontSize: "4vw" }}>ස්වාමීන් වහන්සේලාගෙන් දේශනා සොයන්න</h1>
      {keys.length > 0 ? (
        <div style={styles.gridContainer}>
          {keys.map((key) => {
            const item = mapperData[key];

            return (
              item.is_preacher ?
                <Link
                  to={`/${key}`}
                  key={key}
                  style={styles.card}
                >
                  <div key={key} style={styles.card}>
                    <img
                      src={'images/thero/thumbnails/' + item.picture}
                      alt={item.name_en}
                      style={styles.image}
                    />
                    <div style={styles.cardContent}>
                      <h3 style={styles.nameSi}>{item.name_si}</h3>
                      <p style={styles.nameEn}>{item.name_en}</p>
                    </div>
                  </div>
                </Link> : null
            );
          })}
        </div>
      ) : (
        <p>No keys available.</p>
      )}
    </div>
  );
}

// CSS-in-JS styles for simplicity
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(min(200px, 40%), 1fr))',
    gap: '20px',
    padding: '20px',
    maxWidth: '1200px', // Ensure 5 columns max at 200px each
    margin: '0 auto', // Center the grid container
  },
  card: {
    textDecoration: 'none', // Prevent text underline
    color: 'inherit', // Keep text color consistent
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 8px rgba(0, 0, 0, 0.2)'
    },
  },
  cardContent: {
    padding: '16px',
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: '250px',
    objectFit: 'cover',
  },
  nameSi: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '8px 0 4px',
  },
  nameEn: {
    fontSize: '14px',
    color: '#666',
    margin: '0 0 12px',
  },
  link: {
    textDecoration: 'none',
    color: '#007BFF',
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

export default HomePage;
