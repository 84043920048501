import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { list, preachers } from "./data";

function KeyPage() {
  const { key } = useParams();
  const [values, setValues] = useState(preachers[key].list.map((index) => list[index]));
  const [error, setError] = useState(null);
  const [name_en, setNameEN] = useState(preachers[key].name_en);
  const [name_si, setNameSI] = useState(preachers[key].name_si);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const completeListResponse = await fetch("/complete_list.json");
  //       const mapperResponse = await fetch("/preachers.json");

  //       if (!completeListResponse.ok || !mapperResponse.ok) {
  //         throw new Error("Failed to fetch JSON files");
  //       }

  //       const completeList = await completeListResponse.json();
  //       const mapper = await mapperResponse.json();

  //       if (mapper[key]) {
  //         const mappedValues = mapper[key].list.map((index) => completeList[index]);
  //         setValues(mappedValues);
  //         setNameEN(mapper[key].name_en)
  //         setNameSI(mapper[key].name_si)
  //       } else {
  //         setValues([]);
  //       }
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchData();
  // }, [key]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <div>
      <h1 style={{fontFamily:"N-Abhaya-bold-2019", color:"#fcdf03", margin : '2vw', fontSize:'min(6vw, 50px)'}}>{name_si? name_si + " - " + name_en : "Not Found" }</h1>
      {values.length > 0 ? (
        <div style={styles.gridContainer}>
          {values.map((value, index) => (
            <div style={styles.card}>
              <iframe key={index} style={styles.iframe} 
              src={"https://www.youtube.com/embed/" + value.split("=").reverse()[0] + "?si=Sl0fNUT60lFLIhTu"} 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>

              </iframe>
            </div>
          ))}
        </div>
      ) : (
        <p>Sorry No results.</p>
      )}
    </div>
  );
}

// CSS-in-JS styles for simplicity
const styles = {
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(max(300px,20vw), 1fr))', // Minimum column size of 400px
    gap: '20px', // Space between cards
    padding: '20px',
    margin: '0 auto', // Center the grid container
  },
  card: {
    width: '100%', // Card takes up the full column width
    maxWidth: '500px', // Optional: Limit the maximum card width
    minWidth: '300px', // Minimum card width matches iframe
    aspectRatio: '16 / 9', // Maintain a 16:9 aspect ratio for each card
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
  },
  iframe: {
    width: '100%', // Fill the card width
    height: '100%', // Automatically adjust height based on aspect ratio
    border: 'none',
    display: 'block',
  },
};


export default KeyPage;
